import {themes, useLocalStorage} from "../libs/utils";
import {Link} from "react-router-dom";
import {useEffect} from "react";

function Config() {
    /**
     * Get the current theme from local storage
     * @type {[string, function]}
     * @see https://reactjs.org/docs/hooks-reference.html#usestate
     */
    const [theme, setTheme] = useLocalStorage( 'theme' );
    const [temperatureUnit, setTemperatureUnit] = useLocalStorage( 'temperatureUnit' );
    const [weightUnit, setWeightUnit] = useLocalStorage( 'weightUnit' );
    const themeList = themes();
    /**
     * Change the theme of the application
     */
    function changeTheme( newTheme ) {
        if ( newTheme !== theme ) {
            setTheme(newTheme);
        }
    }

    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    }, [theme]);

    return (
        <>
                <div className='text-xl font-bold text-left pt-8'>
                    Temperature
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text">Celcius</span>
                        <input onChange={ () => setTemperatureUnit('C') } type="radio" name="tempC" className="radio" checked={temperatureUnit==="C"}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text">Farenheight</span>
                        <input onChange={() => setTemperatureUnit('F')} type="radio" name="TempF" className="radio" checked={temperatureUnit==="F"}/>
                    </label>
                </div>
            <div className='text-xl font-bold text-left'>
                Poids
            </div>
            <div className="form-control">
                <label className="label cursor-pointer">
                    <span className="label-text">Grams (g)</span>
                    <input onChange={() => setWeightUnit('G')} type="radio" name="weightG" className="radio" checked={weightUnit==="G"}/>
                </label>
            </div>
            <div className="form-control">
                <label className="label cursor-pointer">
                    <span className="label-text">Ounces (oz)</span>
                    <input onChange={() => setWeightUnit('Oz')} type="radio" name="weightOz" className="radio" checked={weightUnit==="Oz"}/>
                </label>
            </div>
            <div className='text-xl font-bold text-left mb-2'>
                Theme
            </div>
            <div>
                <select onChange={ e => changeTheme(e.target.value) }
                        className="select select-bordered w-full max-w-xs"
                        value={theme}>
                    {themeList.map((cTheme, index) => {
                        return <option key={index} value={cTheme}>{cTheme}</option>
                    } )}
                </select>
            </div>
            <Link to={'/'} className="btn btn-primary btn-block mt-4">Back</Link>
        </>
    )
}

export default Config;
