import {Outlet,useLocation} from "react-router-dom";
import {useLocalStorage} from "../libs/utils";
import {useEffect} from "react";

function Layout() {
    const [theme, setTheme] = useLocalStorage('theme');
    const location = useLocation();

    useEffect(() => {
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
        });
    }, [location]);

    useEffect(() => {
        /**
         * Set the default theme to light if no theme is set
         */
        if (!theme) {
            setTheme('light');
        }
        document.body.setAttribute('data-theme', theme);
    }, [theme, setTheme]);

    return (
        <div className="w-full h-screen flex md:items-center justify-center">
            <div className="h-full mt-3">
                <div className="p-6 md:border-4 md:rounded-xl md:mt-8">
                    <div className="w-80">
                        <div className="flex">
                            <div className="flex-1">
                                <div className="h-6 flex items-center justify-center text-4xl mb-3">
                                    Egg
                                    <svg className='w-10 ml-2 mr-2 fill-primary' version="1.0"
                                         xmlns="http://www.w3.org/2000/svg"
                                         width="1000pt" height="1280pt" viewBox="0 0 1000 1280"
                                         preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                           stroke="none">
                                            <path d="M4699 12335 c-142 -20 -458 -107 -583 -160 -268 -114 -359 -170 -656
                                            -402 -236 -184 -600 -537 -757 -733 -209 -262 -397 -525 -500 -700 -28 -47
                                            -109 -184 -181 -305 -251 -425 -342 -601 -548 -1067 -188 -425 -351 -882 -574
                                            -1603 -68 -219 -162 -569 -174 -646 -3 -20 -30 -160 -61 -312 -50 -250 -114
                                            -638 -120 -727 -2 -19 -6 -80 -9 -135 -4 -55 -11 -165 -16 -245 -21 -314 -7
                                            -772 31 -1065 16 -124 67 -388 95 -494 37 -138 192 -587 259 -752 61 -148 221
                                            -465 314 -622 79 -132 250 -369 395 -547 130 -159 242 -276 386 -400 75 -65
                                            176 -155 224 -199 142 -131 422 -328 632 -444 216 -119 555 -269 769 -339 208
                                            -68 514 -140 675 -158 52 -6 151 -18 220 -25 165 -19 567 -19 725 0 400 48
                                            628 92 855 166 226 74 624 238 765 315 99 54 602 393 700 471 55 44 206 188
                                            336 319 265 269 380 414 603 757 104 161 156 256 276 505 195 403 262 593 379
                                            1065 133 535 155 710 155 1242 0 487 -26 809 -99 1190 -25 132 -47 258 -49
                                            280 -1 22 -5 47 -9 55 -3 8 -18 76 -32 150 -59 304 -96 437 -242 890 -100 312
                                            -268 781 -373 1045 -93 234 -545 1158 -655 1340 -169 278 -477 723 -657 946
                                            -169 211 -475 529 -661 687 -105 90 -523 375 -622 425 -134 67 -371 152 -538
                                            192 -181 44 -511 64 -678 40z"/>
                                        </g>
                                    </svg>
                                    Timer
                                </div>
                            </div>
                        </div>
                    </div>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Layout;
