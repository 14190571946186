import { useLocalStorage , convertSecondsToMMSS, calculateTime , convertGramsToOz , celciusToFahrenheit } from "../libs/utils";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

/**
 * Container for the entire application, centering the content and providing
 * @return {JSX.Element}
 * @constructor
 */
function App() {
    const [poids, setPoids] = useLocalStorage( 'poids' );
    const [temperatureUnit] = useLocalStorage( 'temperatureUnit' );
    const [weightUnit] = useLocalStorage( 'weightUnit' );
    const [tempInitiale, setTempInitiale] = useLocalStorage( 'tempInitiale' );
    const [tempFinale, setTempFinale] = useLocalStorage( 'tempFinale' );
    const [altitude, setAltitude] = useState(0 );
    const [finalTime, setFinalTime] = useState(null);
    const [theme] = useLocalStorage( 'theme' );
    /**
     * Set the theme on the body
     */
    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    } , [theme] );
    /**
     *
     */
    useEffect(() => {
        let currentFinalTime;
        if ( !poids ) {
            setPoids(50);
            setTempInitiale(3);
            setTempFinale(60);
            currentFinalTime = calculateTime(3, 60, 50, 0);
        }
        else {
            currentFinalTime = calculateTime(tempInitiale, tempFinale, poids, altitude);
        }
        if ( !isNaN(currentFinalTime) ) {
            setFinalTime(currentFinalTime);
        }
        else {
            setTempFinale( tempFinale-1 );
            currentFinalTime = calculateTime(tempInitiale, tempFinale, poids, altitude);
            setFinalTime(currentFinalTime);
        }
    }, [tempInitiale, tempFinale, poids, altitude, setPoids , setTempFinale , setTempInitiale ]);

    return (
        <>
            <div className='pt-4'>
                <div className='text-right pb-2'>
                    Weight <span className="font-medium">{weightUnit==='G'?poids+ ' g':convertGramsToOz(poids)+' Oz'}</span>
                </div>
                <div>
                    <input onChange={(e) => setPoids(e.target.value)} type="range" min="20" max="90"
                           value={poids} className="range range-primary"/>
                </div>
            </div>
            <div className='pt-4'>
                <div className='text-right pb-2'>
                    Initial temperature <span className="font-medium">{temperatureUnit==='C'?tempInitiale+ '°C':celciusToFahrenheit(tempInitiale) + '°F'}</span>
                </div>
                <div>
                    <input onChange={(e) => setTempInitiale(e.target.value)} type="range" min="3" max="30"
                           value={tempInitiale} className="range range-primary"/>
                    <div className="w-full flex justify-between text-xs px-2">
                        <span>Fridge</span>

                        <span>Room</span>
                    </div>
                </div>
            </div>
            <div className='pt-4'>
                <div className='text-right pb-2'>
                    Final temperature <span className="font-medium">{temperatureUnit==='C'?tempFinale+ '°C':celciusToFahrenheit(tempFinale) + '°F'}</span>
                </div>
                <div>
                    <input onChange={(e) => setTempFinale(e.target.value)} type="range" min="50" max="85"
                           value={tempFinale} className="range range-primary"/>
                    <div className="w-full flex justify-between text-xs px-2">
                        <span>Soft boiled</span>
                        <span>Medium-boiled</span>
                        <span>Hard-boiled</span>
                    </div>
                </div>
            </div>
            <div className='pt-4'>
                <div className='text-right pb-2'>
                    Altitude <span className="font-medium">{altitude} m</span>
                </div>
                <div>
                    <input onChange={(e) => setAltitude( e.target.value )} type="range" min="0" max="8848" step="200"
                           value={altitude} className="range range-primary"/>
                    <div className="w-full flex justify-between text-xs px-2">
                        <span>New-york</span>
                        <span>Mont Blanc</span>
                        <span>Everest</span>
                    </div>
                </div>
            </div>
            <div>
                <div className='text-5xl text-center font-bold p-8'>
                    {convertSecondsToMMSS(finalTime)}
                </div>
            </div>
            <div className="text-center">
                <Link to={'/timer/' + finalTime} className="btn btn-primary btn-block">Start timer</Link>
            </div>
            <div className="text-center pt-5">
                <Link to={'/config' } className="btn btn-accent btn-block">Parameters</Link>
            </div>
        </>
    );
}

export default App;
