import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";


import Layout from "./components/Layout";
import App from './routes/App';
import Timer from './routes/Timer';
import Config from './routes/Config';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
            path: "",
            element: <App/>,
            },
            {
                path: "/timer/:timeToCount",
                element: <Timer/>,
            },
            {
                path: "/config",
                element: <Config/>,
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

