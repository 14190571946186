import {convertSecondsToMMSS, playAudio, useLocalStorage} from "../libs/utils";
import {Link, useParams} from 'react-router-dom';
import {useEffect, useState} from "react";

function Timer() {
    const [counter,setCounter] = useState( 0 );
    const [started,setStarted] = useState( false );
    const [theme] = useLocalStorage( 'theme' );

    let timer = null;
    let { timeToCount } = useParams();
    timeToCount = parseInt( timeToCount );

    function startTimer() {
        setStarted( true );
        setCounter( 0 );
        timer = setInterval( () => {
            setCounter( counter => counter + 1 );
        }, 1000 );
        setTimeout( () => {
            setCounter( parseInt( timeToCount ) );
            clearInterval( timer );
            playAudio();
        }, timeToCount * 1000 );
    }

    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    } , [theme] );

    useEffect( () => () => {
        clearInterval( timer );
    }, [ timer ] );

    return (
        <>
            <div className="text-6xl font-bold text-center pt-20">
                {convertSecondsToMMSS( timeToCount-counter )}
            </div>
            {!started && <div className='text-center pt-8'>
                <div className={'text-3xl mb-8'}>Is your water boiling ?</div>
                <button onClick={startTimer} className='btn btn-block btn-primary'>Yes !!!</button>
            </div> }
            {started &&
                <div className='mt-8 text-center'>
                    <Link to='/' className='btn btn-sm btn-primary'>Cancel</Link>
                </div>
            }
        </>
    );
}
export default Timer;
