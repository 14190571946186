import {useState} from "react";

/**
 * Convert seconds to MM:SS format
 * @param seconds
 * @return {string}
 */
export function convertSecondsToMMSS(seconds) {
    let minuts = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    if (minuts < 10) {
        minuts = '0' + minuts;
    }
    if (remainingSeconds < 10) {
        remainingSeconds = '0' + remainingSeconds;
    }
    return minuts + ':' + remainingSeconds;
}

export function calculateTime(T_initial, T_final, M, altitude) {
    T_initial = parseInt(T_initial);
    T_final = parseInt(T_final);
    M = parseInt(M);
    altitude = parseInt(altitude);
    const c = 3.3; // capacité thermique spécifique (J/gK)
    const rho = 1.038; // densité (g/cm³)
    const K = 5.4e-3; // conductivité thermique (W/cmK)

    // Approximation de la température d'ébullition de l'eau en fonction de l'altitude (en mètres)
    const T_water = 100 - (altitude / 293);

    const t = Math.pow(M, 2 / 3) * c * Math.pow(rho, 1 / 3) / (K * Math.PI ** 2 * Math.pow(4 * Math.PI / 3, 2 / 3)) * Math.log(0.76 * (T_initial - T_water) / (T_final - T_water));

    return Math.floor(t); // temps de cuisson en secondes
}

export function themes() {
    return [
        "light",
        "dark",
        "cupcake",
        "bumblebee",
        "emerald",
        "corporate",
        "synthwave",
        "retro",
        "cyberpunk",
        "valentine",
        "halloween",
        "garden",
        "forest", "aqua", "lofi", "pastel", "fantasy", "wireframe", "black", "luxury", "dracula", "cmyk",
        "autumn", "business", "acid", "lemonade", "night", "coffee", "winter"
    ];
}

export function playAudio() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();

    fetch('/song/ding1.mp3') // Remplacez 'your-audio-file.mp3' par le chemin de votre fichier audio
        .then(response => response.arrayBuffer())
        .then(data => audioContext.decodeAudioData(data))
        .then(buffer => {
            const source = audioContext.createBufferSource();
            source.buffer = buffer;

            source.connect(audioContext.destination);
            source.start();
        })
        .catch(error => {
            console.error('Une erreur est survenue lors du chargement du fichier audio :', error);
        });
}

export function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

/**
 *
 */
export function convertGramsToOz(grams) {
    // Arrondir 2 chiffres après la virgule
    return Math.round(grams / 28.34952 * 100) / 100;
}

export function celciusToFahrenheit(celcius) {
    return Math.round( celcius * 9 / 5 + 32 );
}
